import "./ProductAvailability.scss";
export function ProductAvailability({
  status,
}: {
  status: "upcoming" | "outOfStock" | "inStock";
}) {
  const map = {
    upcoming: {
      message: "binnenkort beschikbaar",
    },
    outOfStock: {
      message: "uit voorraad",
    },
    inStock: {
      message: "in voorraad",
    },
  };

  return (
    <div class={"productAvailability " + `productAvailability__${status}`}>
      {map[status]?.message}
    </div>
  );
}
