import { Button } from "@kobalte/core/button";
import "./InfoBanner.scss";
import IconX from "@tabler/icons-solidjs/dist/esm/icons/IconX.mjs";
import { infoMessage } from "../stores/shop.store";
import { createSignal } from "solid-js";

export function InfoBanner(props: { isClosable?: boolean }) {
  //   const text = () => props.text;

  const [currentClosedMessage, setCurrentClosedMessage] = createSignal(
    localStorage.getItem("closedInfoMessage")
  );

  function handleClose() {
    localStorage.setItem("closedInfoMessage", infoMessage());
    setCurrentClosedMessage(infoMessage());
  }

  const showBanner = () => {
    if (!infoMessage()) return false;
    return !currentClosedMessage || currentClosedMessage() !== infoMessage();
  };

  return (
    <>
      {showBanner() && (
        <div class="infoBanner siteWidth">
          <div class="infoBanner_wrap">
            <div innerHTML={infoMessage()} />
            {props.isClosable && (
              <Button
                onclick={handleClose}
                class="btn infoBanner_btnClose btn__icon no-outline"
              >
                <IconX />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
