import { createStore } from "solid-js/store";
import { toast } from "../components/Toaster";
import {
  calcTotalItemPrice,
  cartStore,
  clearStore,
  hasItems,
  totalPrice,
  validateCartItems,
} from "./cart.store";
import { userStore } from "./user.store";
import { calcPromoPrice } from "../../googleScripts/sheet/promo";

export type OrderState = "ready" | "sending" | "failed" | "success";

export type Order = {
  state: OrderState;
};

const defaultOrder: Order = {
  state: "ready",
};

export type OrderSummary = {
  totalPrice: number;
  items: {
    id: string;
    name: string;
    name_alt: string;
    variation?: string;
    stock: number;
    amount: number;
    freeUnits?: number;
    price_unit: number;
    price_unit_promo?: number;
    price: number;
    private_props?: { key: string; value: string }[];
  }[];
};

export const [orderStore, setOrderStore] = createStore<Order>(defaultOrder);

export async function sendOrder() {
  setOrderStore((order) => ({ ...order, state: "sending" }));

  await validateCartItems();

  if (!hasItems()) {
    setOrderStore((order) => ({ ...order, state: "failed" }));
    return;
  }

  const orderSummary: OrderSummary = {
    totalPrice: totalPrice(),
    items: cartStore.cartItems.map((item) => {
      const variation = item.product.variations.find(
        (v) => v.id === item.variationId
      );

      return {
        id: item.variationId,
        name: item.product.name,
        name_alt: item.product.alt_name,
        amount: item.amount,
        stock: variation.stock,
        variation: variation.name || "",
        freeUnits: item.freeUnits,
        price_unit: variation?.price,
        price_unit_promo: variation.promo
          ? calcPromoPrice(variation?.price, variation?.promo)
          : undefined,
        price: calcTotalItemPrice(item.product, item.variationId, item.amount),
        private_props: variation.private_props,
      };
    }),
  };

  console.log(orderSummary);
  try {
    const res = await fetch("/api/order", {
      method: "POST",
      body: JSON.stringify({
        buyer: userStore,
        order: orderSummary,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res && res.ok) {
      setOrderStore((order) => ({ ...order, state: "success" }));
      //clear the products in the cart
      clearStore(false);

      window["umami"]?.track("order_success");

      return;
    }
    throw Error("failed to send order");
  } catch (err) {
    setOrderStore((order) => ({ ...order, state: "failed" }));
    console.error(err);
    window["umami"]?.track("order_failed");

    toast.error(
      "We konden jouw bestelling niet plaatsen. Probeer later opnieuw"
    );
  }
}

// async function init() {
// Retrieve data from local storage
// const fetchData = async (): Promise<Order> => {
//   let data = localStorage.getItem("order");
//   if (!data) {
//     return defaultOrder;
//   }
//   const res = JSON.parse(data);
//   if (res.state === "sending") res.state = "ready";
//   return res;
// };
// setOrderStore(await fetchData());
// Create an effect to save the cart to local storage whenever it changes
// createEffect(() => {
//   localStorage.setItem("order", JSON.stringify(orderStore));
// });
// }

// init();
