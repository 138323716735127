import "./Footer.scss";

export function Footer() {
  return (
    <footer class="footer">
      <div class="footer_inner siteWidth">
        {/* <a href="">privacy policy</a> */}
        <a href="https://www.deverwildering.be/">De Verwildering</a>
        <a class="madeWith" href="https://digital.minck.be">
          made with
          <span>♥</span>
          digital.minck.be
        </a>
      </div>
    </footer>
  );
}
