import "./OrderListItem.scss";
import { createMemo } from "solid-js";
import { ProductImage } from "./ProductImage";
import { Button } from "@kobalte/core/button";
import {
  cartStore,
  decreaseAmount,
  increaseAmount,
  removeCartItem,
  setAmount,
} from "../stores/cart.store";
import { PromoPrice } from "./PromoPrice";
import IconMinus from "@tabler/icons-solidjs/dist/esm/icons/IconMinus.mjs";
import IconPlus from "@tabler/icons-solidjs/dist/esm/icons/IconPlus.mjs";
import IconTrash from "@tabler/icons-solidjs/dist/esm/icons/IconTrash.mjs";

import { Price } from "./Price";

export function OrderListItem(props: { id: string }) {
  const item = createMemo(() => {
    return cartStore.cartItems.find((item) => item.variationId === props.id);
  });

  const variation = createMemo(() => {
    return item()?.product?.variations.find((v) => v.id === props.id);
  });

  const reachedMaxStock = createMemo(() => {
    // return false;
    return variation()?.stock <= item()?.amount + item()?.freeUnits;
  });

  const productUrl = () =>
    `/producten/${item().product.category}/${item().product.slug}`;

  return (
    <>
      {item() && (
        <div class="card orderListItem">
          <div class="orderListItem_header">
            <a href={productUrl()} class="orderListItem_image">
              <ProductImage
                category={item().product.category}
                slug={item().product.slug}
                size="thumb"
              />
            </a>
            <div class="orderListItem_price">
              <Price price={item().price} forceDecimals={true} />
              {/* {item().price?.toFixed(2)} € */}
            </div>
            <a class="orderListItem_title" href={productUrl()}>
              <div class="orderListItem_name">{item().product.name}</div>
              {item().product.alt_name && (
                <div class="orderListItem_alt">{item().product.alt_name}</div>
              )}
              {variation()?.name && (
                <div class="orderListItem_variation">{variation().name}</div>
              )}
            </a>
          </div>

          <div class="orderListItem_content">
            <PromoPrice
              price={variation().price}
              promoString={variation().promo}
              className="orderListItem_unitPrice"
            />
            <div class="orderListItem_actions">
              <Button
                onClick={() =>
                  decreaseAmount({ variationId: item().variationId })
                }
                class="btn btn__icon"
                disabled={item().amount <= 0}
              >
                <IconMinus />
              </Button>
              <div class="orderListItem_amount">
                <input
                  type="number"
                  min="0"
                  max={item().freeUnits}
                  value={item().amount}
                  onchange={(e) =>
                    setAmount({
                      variationId: item().variationId,
                      amount: Number(e.currentTarget.value),
                    })
                  }
                />

                {item().freeUnits && (
                  <div class="orderListItem_freeUnits">+{item().freeUnits}</div>
                )}
              </div>
              <Button
                onClick={() =>
                  increaseAmount({ variationId: item().variationId })
                }
                class="btn btn__icon"
                disabled={reachedMaxStock()}
              >
                <IconPlus />
              </Button>{" "}
              <Button
                onClick={() => removeCartItem(item().variationId)}
                class="btn btn__icon"
              >
                <IconTrash />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
