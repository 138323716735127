import IconGardenCart from "@tabler/icons-solidjs/dist/esm/icons/IconGardenCart.mjs";
import IconUserCircle from "@tabler/icons-solidjs/dist/esm/icons/IconUserCircle.mjs";

import "./Navigation.scss";
import { A } from "@solidjs/router";
import { totalItems } from "../stores/cart.store";
import { DropdownMenu } from "@kobalte/core/dropdown-menu";
import { clearStore, isEmptyStore } from "../stores/user.store";

function Navigation() {
  return (
    <div class="navigation">
      <nav class="navigation_inner">
        <div>
          <A href="/">
            <span>Webshop</span> De Verwildering
          </A>
        </div>
        {/* <A href="/plants">Plants</A> */}
        <div>
          {!isEmptyStore() && (
            <DropdownMenu>
              <DropdownMenu.Trigger class="dropdownMenu_trigger btn btn__icon no-outline">
                <IconUserCircle />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content class="dropdownMenu_content">
                  <DropdownMenu.Item
                    class="dropdownMenu_item"
                    onClick={() => {
                      clearStore(true);
                    }}
                  >
                    Verwijder mijn gegevens
                    <div class="dropdownMenu_item-right-slot">
                      {/* <IconTrash /> */}
                    </div>
                  </DropdownMenu.Item>

                  <DropdownMenu.Arrow />
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu>
          )}
          <A href="/cart" class="navigation_cart btn btn__icon">
            <IconGardenCart />
            {totalItems() && (
              <div class="navigation_cartCount">{totalItems()}</div>
            )}
          </A>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
