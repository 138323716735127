import { createStore } from "solid-js/store";
import { createEffect, createRoot, createSignal } from "solid-js";
import { toast } from "../components/Toaster";

// Define the type for your data
export type BuyerDetails = {
  name?: string;
  lastname?: string;
  company?: string;
  taxNr?: string;
  streetAndNumber?: string;
  city?: string;
  postcode?: string;
  telephone?: string;
  email?: string;
  message?: string;
  pickupDate?: string;
  pickupTime?: string;
};

const defaultBuyer = Object.freeze({
  name: "",
  lastname: "",
  company: "",
  taxNr: "",
  streetAndNumber: "",
  city: "",
  postcode: "",
  telephone: "",
  email: "",
  message: "",
  pickupDate: "",
  pickupTime: "",
});

export const [userStore, setUserStore] = createStore<BuyerDetails>({
  ...defaultBuyer,
});

export const [isEmptyStore, setIsEmptyStore] = createSignal(true);

async function init() {
  // Retrieve data from local storage
  const fetchData = async (): Promise<BuyerDetails> => {
    const data = localStorage.getItem("buyer");
    if (!data) {
      return {} as BuyerDetails;
    }
    return JSON.parse(data);
  };

  setUserStore(await fetchData());

  // Create an effect to save the cart to local storage whenever it changes
  createRoot(() => {
    createEffect(() => {
      checkIfEmpty();
      localStorage.setItem("buyer", JSON.stringify(userStore));
    });
  });
}

export function clearStore(withToast: boolean = true) {
  setUserStore({ ...defaultBuyer });

  withToast &&
    toast.success(
      "Jouw persoonlijke gegevens worden niet meer onthouden op deze computer"
    );
}

function checkIfEmpty() {
  let isEmpty = true;
  Object.keys(defaultBuyer).forEach((key) => {
    if (userStore[key] !== defaultBuyer[key]) isEmpty = false;
    // console.log(key, userStore[key], defaultBuyer[key]);
  });
  setIsEmptyStore(isEmpty);
}

init();
