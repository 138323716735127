import { createEffect, createSignal } from "solid-js";
import { calcPromoPrice } from "../../googleScripts/sheet/promo";
import "./Price.scss";

export function Price(props: {
  price: number;
  promoString?: string;
  forceDecimals?: boolean;
}) {
  const price = () => props.price;
  const promoString = () => props.promoString;

  const decimals =
    props.forceDecimals || price().toString().split(".")[1]?.length ? 2 : 0;

  const [promoPrice, setPromoPrice] = createSignal<number>();

  createEffect(() => {
    setPromoPrice(calcPromoPrice(price(), promoString()));
  });

  return (
    <div class={"price " + (promoPrice() ? "price__promo" : "")}>
      <span class="price_standard">{price().toFixed(decimals)}</span>
      {promoPrice() && (
        <span class="price_promo">{promoPrice().toFixed(decimals)}</span>
      )}
      €
    </div>
  );
}
