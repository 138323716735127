import "./ProductImageGallery.scss";
import { ProductImage } from "./ProductImage";
import IconCircleArrowLeftFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCircleArrowLeftFilled.mjs";
import IconCircleArrowRightFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCircleArrowRightFilled.mjs";
import { createSignal } from "solid-js";

export function ProductImageGallery({
  images,
  category,
}: {
  images: string[];
  category: string;
}) {
  const [currentImageIndex, setCurrentImageIndex] = createSignal(0);
  function forward() {
    // console.log("forward");
    const update = currentImageIndex() + 1;
    if (update >= images.length) return;
    setCurrentImageIndex(update);
  }

  function backwards() {
    // console.log("backwards");
    const update = currentImageIndex() - 1;
    if (update < 0) return;
    setCurrentImageIndex(update);
  }
  return (
    <div class="productImageGallery">
      {images.map((imageName, i) => (
        <div
          class={
            "productImageGallery_item " +
            (currentImageIndex() === i ? "productImageGallery_item__show" : "")
          }
        >
          <ProductImage category={category} imageName={imageName} />
        </div>
      ))}

      {images.length > 1 && (
        <div>
          <button
            class="productImageGallery_btnNext"
            onclick={forward}
            disabled={currentImageIndex() >= images.length - 1}
          >
            <IconCircleArrowRightFilled size={40} />
          </button>
          <button
            class="productImageGallery_btnPrevious"
            onclick={backwards}
            disabled={currentImageIndex() <= 0}
          >
            <IconCircleArrowLeftFilled size={40} />
          </button>
        </div>
      )}
    </div>
  );
}
