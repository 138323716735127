import "./PromoPrice.scss";
import { Promo } from "./Promo";
import { Price } from "./Price";

export function PromoPrice(props: {
  price: number;
  promoString?: string;
  className?: string;
}) {
  const promoString = () => props.promoString;
  const price = () => props.price;
  const className = () => props.className;
  return (
    <div class={"promoPrice " + (className() || "")}>
      <Price price={price()} promoString={promoString()} />
      {promoString() && <Promo promoString={promoString()} />}
    </div>
  );
}
