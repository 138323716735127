import { createEffect, createSignal } from "solid-js";
import { promoType } from "../../googleScripts/sheet/promo";
import "./Promo.scss";

export function Promo(props: { promoString?: string; size?: number }) {
  const promoString = () => props.promoString || "";
  const size = () => props.size || 12;

  const [promoText, setPromoText] = createSignal("promo");

  createEffect(() => {
    const type = promoType(promoString());

    if (type === "%") return setPromoText("-" + promoString());
    if (type === "+") return setPromoText(promoString() + " gratis");

    return setPromoText("promo");
  });

  return (
    <div class="promo" style={{ "--size": size() + "px" }}>
      {promoText()}
    </div>
  );
}
