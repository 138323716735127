const PROMO_MAP = {
  "€": {
    parse: (s: string) => {
      const parts = s.split("€");
      if (parts.length !== 2) return;

      const [first, second] = parts.map((s) => s.trim());

      if (second !== "") return;

      return parseFloat(first.replace(",", "."));
    },
  },
  "+": {
    parse: (s: string) => {
      const parts = s.split("+");
      if (parts.length !== 2) return;

      const [each, extra] = parts.map((s) =>
        parseInt(s.replace(",", ".").trim())
      );

      if (!isNumber(each) || !isNumber(extra)) return;

      return { each, extra };
    },
  },
  "%": {
    parse: (s: string) => {
      const parts = s.split("%");
      if (parts.length !== 2) return;

      const [first, second] = parts.map((s) => s.trim());

      if (second !== "") return;

      return parseFloat(first.replace(",", "."));
    },
  },
};

export type PROMO_TYPES = keyof typeof PROMO_MAP;

export function calcPromoPrice(price: number, promo: string = ""): number {
  if (promo.includes("€")) return PROMO_MAP["€"].parse(promo);

  if (promo.includes("%"))
    return price - price * PROMO_MAP["%"].parse(promo) * 0.01;

  return;
}

export function promoType(s: string): PROMO_TYPES | undefined {
  if (!isPromoFormat(s)) return;

  if (s.includes("€")) return "€";
  if (s.includes("%")) return "%";
  if (s.includes("+")) return "+";

  return;
}

export function isPromoFormat(s: string) {
  return !!cleanPromoString(s);
}

export function cleanPromoString(s: string = "") {
  for (const p of Object.values(PROMO_MAP)) {
    const res = p.parse(s);
    if (res) return s.trim();
  }

  return "";
}

export function calcExtraUnits(amount: number, promo: string): number {
  if (promoType(promo) !== "+") return 0;

  const res = PROMO_MAP["+"].parse(promo);

  if (!res) return 0;

  const { each, extra } = res;

  const times = Math.floor(amount / each);

  return times * extra;
}

function isNumber(value: any) {
  return typeof value === "number" && !isNaN(value);
}
