import "./Toaster.scss";
import { Toast, toaster } from "@kobalte/core/toast";

import IconAlertTriangleFilled from "@tabler/icons-solidjs/dist/esm/icons/IconAlertTriangleFilled.mjs";
import IconCircleCheckFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCircleCheckFilled.mjs";

function show(message: string) {
  toaster.clear();
  return toaster.show((props) => (
    <Toast toastId={props.toastId} class="toast">
      <div class="toast_content">
        <div>
          {/* <Toast.Title class="toast_title">Event has been created</Toast.Title> */}
          <Toast.Description class="toast_description">
            {message}
          </Toast.Description>
        </div>
        <Toast.CloseButton class="toast_close-button">x</Toast.CloseButton>
      </div>
      <Toast.ProgressTrack class="toast_progress-track">
        <Toast.ProgressFill class="toast_progress-fill" />
      </Toast.ProgressTrack>
    </Toast>
  ));
}
function success(message: string) {
  toaster.clear();
  return toaster.show((props) => (
    <Toast toastId={props.toastId} class="toast toast__success">
      <div class="toast_content">
        <IconCircleCheckFilled class="toast_icon" />
        <Toast.Description class="toast_description">
          {message}
        </Toast.Description>
      </div>
    </Toast>
  ));
}

function error(message: string) {
  toaster.clear();
  return toaster.show((props) => (
    <Toast toastId={props.toastId} class="toast toast__error">
      <div class="toast_content">
        <IconAlertTriangleFilled class="toast_icon" size={30} />
        <Toast.Description class="toast_description">
          {message}
        </Toast.Description>
      </div>
    </Toast>
  ));
}
// function promise<T, U>(
//   promise: Promise<T> | (() => Promise<T>),
//   options: {
//     loading?: JSX.Element;
//     success?: (data: T) => JSX.Element;
//     error?: (error: U) => JSX.Element;
//   }
// ) {
//   return toaster.promise(promise, (props) => (
//     <Toast
//       toastId={props.toastId}
//       classList={{
//         toast: true,
//         "toast-loading": props.state === "pending",
//         "toast-success": props.state === "fulfilled",
//         "toast-error": props.state === "rejected",
//       }}
//     >
//       <Switch>
//         <Match when={props.state === "pending"}>{options.loading}</Match>
//         <Match when={props.state === "fulfilled"}>
//           {/* @ts-ignore */}
//           {options.success?.(props.data)}
//         </Match>
//         <Match when={props.state === "rejected"}>
//           {options.error?.(props.error)}
//         </Match>
//       </Switch>
//     </Toast>
//   ));
// }
// function custom(jsx: () => JSX.Element) {
//   return toaster.show((props) => <Toast toastId={props.toastId}>{jsx}</Toast>);
// }
function dismiss(id: number) {
  return toaster.dismiss(id);
}
export const toast = {
  show,

  success,
  error,
  // promise,
  // custom,
  dismiss,
};
