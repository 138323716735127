import { JSXElement } from "solid-js";
import "./App.scss";

import Navigation from "./components/Navigation";
import { Toast } from "@kobalte/core/toast";
import { Footer } from "./components/Footer";
import { CookieBanner } from "./components/CookieBanner";

function App({ children }: { children: JSXElement }) {
  return (
    <>
      <Navigation />
      <main>
        {children}

        <Toast.Region limit={3} duration={3000}>
          <Toast.List class="toast_list" />
        </Toast.Region>

        <CookieBanner />
      </main>
      <Footer />
    </>
  );
}

export default App;
