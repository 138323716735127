import "./ProductListItem.scss";
import { ListProduct } from "../../googleScripts/sheet/types";
import { ProductImage } from "./ProductImage";
import { Promo } from "./Promo";
import { Price } from "./Price";
import { ProductAvailability } from "./ProductAvailability";
import { A } from "@solidjs/router";

function ProductListItem({ product }: { product: ListProduct }) {
  const productUrl = () => `/producten/${product.category}/${product.slug}`;

  return (
    <A class="productListItem outline" href={productUrl()}>
      <div class="productListItem_imgContainer">
        <ProductImage
          category={product.category}
          slug={product.slug}
          size="normal"
        />
      </div>
      <div class="productListItem_header">
        <h2 class="productListItem_name">{product.name}</h2>
        <h3 class="productListItem_altName">{product.alt_name}</h3>
      </div>
      <div class="productListItem_content">
        {product.summary && (
          <p class="productListItem_description">{product.summary}…</p>
        )}
      </div>

      <div class="productListItem_footer">
        <div class="productListItem_priceDetails">
          <span class="productListItem_variations">
            {product.has_price_variations && <>vanaf </>}
            <Price price={product.start_price} />
          </span>

          {product.has_promo && <Promo />}
        </div>

        {product.upcoming && <ProductAvailability status="upcoming" />}

        <div class="btn btn__primary btn__fullWidth productListItem_detailBtn">
          Detail
        </div>
      </div>
    </A>
  );
}

export default ProductListItem;
