// import { For } from "solid-js";
import "./ProductBrowser.scss";
import ProductListItem from "./ProductListItem";
import { categories, getProductList } from "../stores/shop.store";
import {
  createEffect,
  createMemo,
  createResource,
  createSignal,
  Show,
} from "solid-js";
import { Button } from "@kobalte/core/button";
import IconSearch from "@tabler/icons-solidjs/dist/esm/icons/IconSearch.mjs";
import IconXboxX from "@tabler/icons-solidjs/dist/esm/icons/IconXboxXFilled.mjs";

import { useSearchParams } from "@solidjs/router";
import { TextField } from "@kobalte/core/text-field";
import { debounce } from "@solid-primitives/scheduled";
import { Loader } from "./Loader";
import Info from "./Info";

const ALL_TYPES = "alles";

function ProductBrowser() {
  // load url params
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategoryName = createMemo(
    () => searchParams.category || categories()[0].name
  );

  const setSelectedCategoryName = (name: string) => {
    setSearchParams(
      { category: name, type: "", search: "" },
      { replace: false }
    );
  };

  const types = createMemo(() => {
    const types = [
      ...(categories().find((c) => c.name === selectedCategoryName())?.types ||
        []),
    ];
    if (types.length > 0) types.unshift(ALL_TYPES);
    return types;
  });

  const selectedType = createMemo(() => searchParams.type || ALL_TYPES);
  const setSelectedType = (type: string) => {
    if (type === ALL_TYPES) type = "";
    setSearchParams({ type }, { replace: false });
  };

  const [searchValue, setSearchValueORI] = createSignal(
    searchParams.search || ""
  );

  const setSearchValueDebounced = (value: string, replace = false) => {
    setSearchValueORI(value);
    debounceSearchInput(value, replace);
  };

  const setSearchValueInstant = (value: string, replace = false) => {
    setSearchValueORI(value);
    setSearchParams({ search: value }, { replace });
  };

  const debounceSearchInput = debounce(
    (searchString: string, replace = false) => {
      //@ts-ignore
      setSearchParams({ search: searchString }, { replace });
    },
    400
  );

  createEffect(() => {
    debounceSearchInput(searchValue(), true);
  });

  createEffect(() => {
    setSearchValueORI(searchParams.search || "");
  });

  const productListResource = createMemo(() =>
    createResource({ categoryName: selectedCategoryName() }, getProductList)
  );

  const productList = () => productListResource()[0]();

  const filteredProductList = createMemo(() => {
    const searchParts =
      searchParams?.search?.toLocaleLowerCase()?.split(" ") || [];

    return (
      productList()
        // ?.data()
        ?.filter(
          (p) =>
            !selectedType() ||
            selectedType() === ALL_TYPES ||
            p.type === selectedType()
        )
        .filter(
          (p) =>
            searchParts.every((part) =>
              (p.alt_name.toLowerCase() + p.name.toLowerCase()).includes(part)
            )
          // searchParts.every((part) => p.alt_name.toLowerCase().includes(part))
        ) || []
    );
  });

  const notFoundMessage = () => {
    let m = (() => {
      if (!selectedType() || !selectedCategoryName()) return "niets gevonden";
      if (selectedType() === ALL_TYPES)
        return `geen ${selectedCategoryName()} gevonden`;
      return `geen ${selectedType()} gevonden`;
    })();

    return m + ` voor "${searchParams.search}"`;
  };

  return (
    <>
      <div class="productFilter siteWidth">
        <div class="productFilter_categories">
          {categories().map((c) => (
            <>
              <Button
                onClick={() => setSelectedCategoryName(c.name)}
                class={
                  "btn " +
                  (selectedCategoryName() !== c.name
                    ? "productFilter_category__deselected"
                    : "productFilter_category__selected")
                }
              >
                {c.name}
              </Button>{" "}
            </>
          ))}
        </div>
        <TextField
          value={searchValue()}
          onChange={setSearchValueDebounced}
          class="textfield productFilter_searchField"
        >
          <div class="textfield_icon">
            <IconSearch size={20} />
          </div>
          <TextField.Input
            class="textfield_input"
            placeholder={`Zoek op naam binnen '${selectedCategoryName()}'`}
          />
          <Button
            class="btn productFilter_searchFieldClearBtn no-outline"
            disabled={!searchValue()}
            onClick={() => setSearchValueInstant("")}
          >
            <IconXboxX size={20} />
          </Button>
        </TextField>
        {types().length > 0 && (
          <>
            <div class="productFilter_types">
              {types().map((type) => (
                <Button
                  onClick={() => setSelectedType(type)}
                  class={
                    "btn productFilter_type " +
                    (selectedType() === type
                      ? "productFilter_type__selected"
                      : "productFilter_type__deselected")
                  }
                >
                  {type}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>

      <Show
        when={!productListResource()[0].loading}
        fallback={
          <div class="siteWidth">
            <Loader label="" />
          </div>
        }
      >
        <Show
          when={productList()}
          fallback={
            <div class="siteWidth">
              <Info message="Error loading products" />{" "}
            </div>
          }
        >
          <Show
            when={filteredProductList()?.length}
            fallback={
              <div class="siteWidth siteWidth__centered">
                <Info message={notFoundMessage()} />
              </div>
            }
          >
            <div class="productList siteWidth siteWidth__full">
              {filteredProductList()?.map((p) => (
                <ProductListItem product={p} />
              ))}
            </div>
          </Show>
        </Show>
      </Show>
    </>
  );
}

export default ProductBrowser;
