import "./OrderSuccess.scss";
import { clearStore, isEmptyStore, userStore } from "../stores/user.store";

import IconCircleCheckFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCircleCheckFilled.mjs";
import IconCheck from "@tabler/icons-solidjs/dist/esm/icons/IconCheck.mjs";
import Callout from "./Callout";
import { A } from "@solidjs/router";

function OrderSuccess() {
  const cacheName = userStore.name;

  function removeData() {
    clearStore();
  }

  return (
    <div class="orderSuccess">
      <div class="card">
        {/* <div class="orderSuccess_content"> */}
        <div class="orderSuccess_header">
          <h1>
            <IconCircleCheckFilled class="orderSuccess_icon" size={40} />
            Gelukt <span class="orderSuccess_name">{cacheName}</span>
          </h1>
          <p>
            Bedankt voor jouw bestelling. Je ontvangt nog een bevestigingsmail.
          </p>
        </div>

        <div class="orderSuccess_content">
          <Callout type="info">
            Vergeet niet om je spamfolder te bekijken als je de e&#8209;mail
            niet direct ziet.
          </Callout>

          <A href="/" class="btn btn__primary btn__fullWidth mt-1">
            Verder winkelen
          </A>
        </div>
      </div>
      {/* </div> */}

      <div class="card">
        <div class="orderSuccess_content">
          <p>
            Wil je jouw persoonlijke gegevens op deze computer behouden voor
            jouw volgende bestelling?
          </p>

          <button
            class="btn btn__fullWidth orderSuccess_btnDelete"
            onClick={() => removeData()}
            disabled={isEmptyStore()}
          >
            {isEmptyStore() ? (
              <>
                <IconCheck size={20} /> gegevens verwijderd
              </>
            ) : (
              "Nee, verwijder mijn gegevens"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccess;
