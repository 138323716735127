import { useParams } from "@solidjs/router";

import ProductDetail from "../components/ProductDetail";

function Product() {
  const { name_slug, category } = useParams();

  if (!name_slug) return <h1>Product niet gevonden</h1>;

  return (
    <div class="siteWidth">
      <ProductDetail productSlug={name_slug} categoryName={category} />
    </div>
  );
}

export default Product;
