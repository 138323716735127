import "./OrderList.scss";
import {
  totalPrice,
  cartStore,
  hasItems,
  validateCartItems,
} from "../stores/cart.store";
import { A, Router, useNavigate } from "@solidjs/router";
import { OrderListItem } from "./OrderListItem";
import { Price } from "./Price";
import Callout from "./Callout";
import { Button } from "@kobalte/core/button";

function OrderList() {
  const navigate = useNavigate();
  async function handleSubmit() {
    // await validateCartItems();

    // if (!hasItems()) return;

    navigate("/checkout");
  }
  return (
    <div class="orderList">
      {cartStore.cartItems.map((item) => (
        <OrderListItem id={item.variationId} />
      ))}

      <div class="card orderList_total">
        <div class="orderList_totalLabel">totaal</div>
        <div>
          <Price price={totalPrice()} forceDecimals={true} />
        </div>
      </div>

      <Callout type="info" title="Opgelet">
        <span>
          Plantgoed wordt altijd afgehaald aan de kwekerij, wij versturen niet
          per post.
          <br />
          Betalen kan bij afhaling contant of met de bankkaart.
          <br />
          Breng een doos of bak mee om je plantgoed te vervoeren.
        </span>
      </Callout>
      <div class="orderList_actions">
        <A href="/" class="btn outline">
          verder winkelen
        </A>
        <Button
          onClick={handleSubmit}
          class={
            "orderList_checkout btn btn__primary" +
            (hasItems() ? "" : " btn__disabled")
          }
        >
          Afrekenen
        </Button>
      </div>
    </div>
  );
}
export default OrderList;
