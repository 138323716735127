export function addSuffixToFilename(filename, suffix) {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // No extension found, simply append the suffix
    return filename + suffix;
  }

  const name = filename.slice(0, lastDotIndex);
  const extension = filename.slice(lastDotIndex);
  return `${name}${suffix}${extension}`;
}
