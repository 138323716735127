import "./Info.scss";
// import IconAlertCircleFilled from "@tabler/icons-solidjs/dist/esm/icons/IconAlertCircleFilled.mjs";
// import IconCircleCheckFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCircleCheckFilled.mjs";
// import IconInfoCircleFilled from "@tabler/icons-solidjs/dist/esm/icons/IconInfoCircleFilled.mjs";
// import { Dynamic } from "solid-js/web";

function Info(props: {
  type?: "info" | "warning" | "success";
  message: string;
}) {
  // const icons = {
  //   info: IconInfoCircleFilled,
  //   warning: IconAlertCircleFilled,
  //   success: IconCircleCheckFilled,
  // };
  const message = () => props.message;
  const type = () => props.type || "info";

  return (
    <div class={`info info__${type()}`}>
      {/* <div class={`info `}> */}
      {/* <div class="info_icon">
        <Dynamic component={icons[type()]} />
      </div> */}
      <div class="info_message">
        <div class="info_message">{message()}</div>
      </div>
    </div>
  );
}

export default Info;
