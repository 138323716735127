/**
 *
 * @param {*} dateString dd/mm/jjjj format
 * @returns
 */
export function dateStringToDate(dateString) {
  const [day, month, year] = dateString.split("/").map(Number);
  const d = new Date(Date.UTC(year, month - 1, day));
  // console.log("d", dateString, d);

  // d.setHours(0, 0, 0, 0);
  return d;
}

/**
 *
 * @param {string} dateString dd/mm/jjjj format
 * @returns
 */
export function isValidStringDateFormat(s) {
  const date = new Date(dateStringToDate(s));
  return !isNaN(date.getTime());
}

/**
 *
 * @param {Date} date
 * @returns dd/mm/jjjj format
 */
export function formatShortDate(date) {

  return date.toLocaleDateString("nl-BE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // timeZone: "UTC",
  });

}

/**
 *
 * @param {Date} date
 * @returns ex: monday 1/12/2024
 */

export const formatLongDate = (date) => {
  // if (typeof date === "string") {
  //   date = dateStringToDate(date);
  // }

  if (!(date instanceof Date)) {
    throw new Error("Invalid date object");
  }

  const days = [
    "zondag",
    "maandag",
    "dinsdag",
    "woensdag",
    "donderdag",
    "vrijdag",
    "zaterdag",
  ];

  const dayName = days[date.getUTCDay()];

  return `${dayName} ${formatShortDate(date)}`;
};

// export function isOnSameDay(d1, d2) {

//   d1.setHours(0, 0, 0, 0);
//   d2.setHours(0, 0, 0, 0);

//   const res = (
//     d1.getFullYear() === d2.getFullYear() &&
//     d1.getMonth() === d2.getMonth() &&
//     d1.getUTCDate() === d2.getUTCDate()
//   );

//   console.log("res", res);
//   return res;
// }
export function isOnSameDay(d1, d2) {
  return d1.toDateString() === d2.toDateString();
}
