import { createStore, produce } from "solid-js/store";
import { createEffect, createMemo, createRoot, createSignal } from "solid-js";
import { toast } from "../components/Toaster";
import { setOrderStore } from "./order.store";
import { FullProduct } from "../../googleScripts/sheet/types";

import {
  calcExtraUnits,
  calcPromoPrice,
} from "../../googleScripts/sheet/promo";
import { getFullProduct } from "./shop.store";

// Define the type for your data
//TODO: only reference to the product variation should be stored in localstorage/cart
// and refetch all the json data again on browser refresh
// otherwise one could keep products in localstorage cart with out of sync prices
// this  means when a product has a price change the cart should be updated with that price
// also means that when a product is removed / out of stock from the google sheet, the cart should be updated accordingly.

export type CartItem = {
  variationId: string;
  product: FullProduct;
  amount: number;
  price: number;
  freeUnits: number;
  canBeOrdered: boolean;
};

export type CartStore = {
  cartItems: CartItem[];
};

export const [cartStore, setCartStore] = createStore<CartStore>({
  cartItems: [],
});

//should be moved all to the store?

const { totalPrice, totalItems, state, setState, hasItems, isReady } =
  createRoot(() => {
    const totalPrice = createMemo(() => {
      return cartStore.cartItems.reduce(
        (total, item) =>
          total +
          calcTotalItemPrice(item.product, item.variationId, item.amount),
        0
      );
    });

    const totalItems = createMemo(() =>
      cartStore.cartItems.reduce((total, item) => total + item.amount, 0)
    );

    const hasItems = createMemo(() =>
      cartStore.cartItems.reduce((total, item) => total + item.amount, 0)
    );

    const [state, setState] = createSignal<"initial" | "loading" | "ready">(
      "initial"
    );

    const isReady = createMemo(() => state() === "ready");

    return { totalPrice, totalItems, hasItems, state, setState, isReady };
  });

// Calculate total price

export { totalPrice, totalItems, state, hasItems, isReady };

export async function init() {
  setState("loading");

  const cartData: CartItem[] = JSON.parse(
    localStorage.getItem("cartItems") || "[]"
  );

  setCartStore("cartItems", cartData);

  await validateCartItems();

  createRoot(() => {
    createEffect(() => {
      // Create an effect to save the cart to local storage whenever it changes
      localStorage.setItem("cartItems", JSON.stringify(cartStore.cartItems));
    });
  });

  //validate cart items every 5 minutes
  setInterval(() => validateCartItems(), 5 * 1000 * 60);

  setState("ready");
}

export async function validateCartItems() {
  //get the latest version of the full products
  const fullProductsLatest = await Promise.all(
    cartStore.cartItems.map((item) =>
      getFullProduct({
        categoryName: item.product.category,
        productSlug: item.product.slug,
        refetch: true,
      })
    )
  );

  // const cartDataChecked: CartItem[] = [];

  setCartStore(
    "cartItems",
    produce((items: CartItem[]) => {
      items.forEach((item) => {
        const product = fullProductsLatest.find(
          (p) => p?.slug === item?.product?.slug
        );

        //product no longer found
        if (!product) {
          console.warn("product no longer found", item.product.slug);
          items.splice(items.indexOf(item), 1);
          return;
        }

        //variation still valid?
        const variation = getVariationById(product, item.variationId);

        if (!variation) {
          console.warn("variation no longer valid:", item.variationId);
          items.splice(items.indexOf(item), 1);
          return;
        }

        //variation changed to upcoming?
        if (variation.upcoming) {
          console.warn("variation is upcoming:", item.variationId);
          items.splice(items.indexOf(item), 1);
          return;
        }

        //update the latest version of the product
        item.product = product;
        //recalc
        updateItemAmount(item, item.amount);
      });
    })
  );
}

export function clearStore(withToast: boolean = true) {
  setCartStore("cartItems", []);
  withToast &&
    toast.success("Jouw producten worden niet meer onthouden op deze computer");
}

// Function to add an item to the cart
export function addCartItem({
  product,
  variationId,
  amount = 1,
}: {
  product: FullProduct;
  variationId: string;
  amount?: number;
}) {
  // console.log("adding cart item", product.name, id);
  setCartStore(
    "cartItems",
    produce((items: CartItem[]) => {
      const existingItem = items.find(
        (item) => item.variationId === variationId
      );
      if (existingItem) {
        updateItemAmount(existingItem, existingItem.amount + 1);
      } else {
        items.push(
          updateItemAmount(
            {
              variationId: variationId,
              product,
              amount,
              price: calcTotalItemPrice(product, variationId, amount),
              freeUnits: calcExtraUnits(
                amount,
                getVariationById(product, variationId)?.promo
              ),
              canBeOrdered: false,
            },
            amount
          )
        );
      }
    })
  );

  toast.success(product.name + " is toegevoegd");

  //when ever the store changes, set the state of the order back on ready
  setOrderStore((store) => ({ ...store, state: "ready" }));
}

// Function to remove an item from the cart
export function removeCartItem(id: string) {
  setCartStore("cartItems", (items) =>
    items.filter((item) => item.variationId !== id)
  );
  //when ever the store changes, set the state of the order back on ready
  setOrderStore((store) => ({ ...store, state: "ready" }));
}

// Function to increase the amount of an item in the cart
export function increaseAmount({
  variationId,
  increment = 1,
}: {
  variationId: string;
  increment?: number;
}) {
  const item = cartStore.cartItems.find(
    (item) => item.variationId === variationId
  );
  item &&
    setAmount({
      variationId,
      amount: Math.min(
        getVariationById(item.product, item.variationId).stock,
        item.amount + increment
      ),
    });
}

// Function to decrease the amount of an item in the cart
export function decreaseAmount({
  variationId,
  decrement = 1,
}: {
  variationId: string;
  decrement?: number;
}) {
  const item = cartStore.cartItems.find(
    (item) => item.variationId === variationId
  );
  item &&
    setAmount({ variationId, amount: Math.max(0, item.amount - decrement) });
}

export function setAmount({
  variationId,
  amount,
}: {
  variationId: string;
  amount: number;
}) {
  setCartStore(
    "cartItems",
    (item) => item.variationId === variationId,
    produce((item: CartItem) => {
      // const item = items.find();
      if (item) updateItemAmount(item, amount);
    })
  );
  //when ever the store changes, set the state of the order back on ready
  setOrderStore((store) => ({ ...store, state: "ready" }));
}

function updateItemAmount(item: CartItem, amount: number) {
  const targetAmount = amount;
  item.amount = 0;
  item.price = calcTotalItemPrice(item.product, item.variationId, item.amount);
  item.freeUnits = calcExtraUnits(
    item.amount,
    getVariationById(item.product, item.variationId)?.promo
  );

  //make sure the total amount (with promo effect)is not higher than the stock
  const { stock } = getVariationById(item.product, item.variationId);

  while (item.amount < targetAmount) {
    item.amount++;
    item.freeUnits = calcExtraUnits(
      item.amount,
      getVariationById(item.product, item.variationId)?.promo
    );

    if (item.amount + item.freeUnits > stock) {
      item.amount--;
      item.freeUnits = calcExtraUnits(
        item.amount,
        getVariationById(item.product, item.variationId)?.promo
      );

      break;
    }
  }
  item.price = calcTotalItemPrice(item.product, item.variationId, item.amount);
  item.canBeOrdered = item.amount + item.freeUnits < stock;

  return item;
}

export function calcTotalItemPrice(
  product: FullProduct,
  variationId: string,
  amount: number = 1
) {
  const variation = getVariationById(product, variationId);

  const price =
    calcPromoPrice(variation?.price, variation?.promo) || variation?.price || 0;
  return amount * price;
}

export function getVariationByName(
  product: FullProduct,
  variationName: string
) {
  return product.variations.find((v) => v.name === variationName);
}

export function getVariationById(product: FullProduct, variationId: string) {
  const o = product.variations.find((v) => v.id === variationId);
  // if (!o) console.log("getVariationById", variationId, product);
  return o;
}

init();
