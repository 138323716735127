export function createCachedJsonFetcher() {
  const cache = new Map();

  async function load({
    url,
    refetch = false,
  }: // retries = 3,
  // delay = 100,
  {
    url: string;
    refetch?: boolean;
    // retries?: number;
    // delay?: number;
  }) {
    // return await fetch(url).then((res) => res.json());

    if (!refetch && cache.has(url) && cache.get(url)) {
      // console.log("from cache", url);
      return cache.get(url); // Use cache if available
    }

    // for (let attempt = 1; attempt <= retries; attempt++) {
    //   try {
    //     // if (attempt > 1) console.log("attempt " + attempt + " loading:", url);

    const response = await fetch(url);

    //     if (response.status === 404) {
    //       return null;
    //     }

    //     if (!response.ok) throw new Error("Failed to fetch data");

    //     try {
    const data = await response.json();
    cache.set(url, data);
    console.log("from fetch", url);
    return data;
    //     } catch (err) {
    //       //this will most likely load the index.html page because of SPA redirect rules
    //       // abortLoop = true;
    //       return null;
    //     }
    //   } catch (error) {
    //     if (attempt === retries)
    //       throw new Error(`Fetching ${url} failed after ${retries} attempts`);

    //     await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    //     delay = delay * 2;
    //   }
    // }
  }

  return { load };
}
