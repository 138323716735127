import { createSignal } from "solid-js";
import "./CookieBanner.scss";

export function CookieBanner() {
  const [cookiesAccepted, setCookiesAccepted] = createSignal(
    localStorage.getItem("cookiesAccepted") === "true"
  );

  function handleOk() {
    setCookiesAccepted(true);
    localStorage.setItem("cookiesAccepted", "true");
  }

  return (
    <>
      {!cookiesAccepted() && (
        <div class="cookieBanner">
          <span>
            <img src="/icon-cookie.png" />
          </span>
          <span>
            Cookies worden gebruikt om deze site te laten functioneren
          </span>
          <button class="btn btn__primary" onClick={handleOk}>
            ok
          </button>
        </div>
      )}
    </>
  );
}
