// import { For } from "solid-js";
import CheckoutForm from "../components/CheckoutForm";

// import { Popover } from "@kobalte/core/popover";
// import { CrossIcon } from "some-icon-library";

function Checkout() {
  return (
    <div class="siteWidth">
      {/* <h1>Checkout</h1> */}
      <CheckoutForm />
    </div>
  );
}

export default Checkout;
