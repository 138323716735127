import "./ReturnToTop.scss";
import { createSignal, onCleanup, onMount } from "solid-js";
import IconArrowUp from "@tabler/icons-solidjs/dist/esm/icons/IconArrowUp.mjs";

export function ReturnToTop() {
  const [isVisible, setIsVisible] = createSignal(false);

  const handleScroll = () => {
    setIsVisible(window.scrollY > 800);
  };

  onMount(() => {
    window.addEventListener("scroll", handleScroll);
    onCleanup(() => window.removeEventListener("scroll", handleScroll));
  });

  return (
    <div class={"returnToTop " + (isVisible() ? "returnToTop__visible" : "")}>
      <button
        class="btn btn__icon"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <IconArrowUp />
      </button>
    </div>
  );
}
