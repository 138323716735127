// import { For } from "solid-js";
import "./Cart.scss";
import { cartStore, validateCartItems } from "../stores/cart.store";
import Callout from "../components/Callout";
import OrderList from "../components/OrderList";
import { onMount } from "solid-js";
import { A } from "@solidjs/router";

function Cart() {
  onMount(() => {
    validateCartItems();
  });

  return (
    <div class="siteWidth">
      <h1>Jouw kruiwagen</h1>

      {!cartStore.cartItems.length && (
        <div class="cart_empty">
          <Callout>Jouw kruiwagen is leeg</Callout>
          <A href="/" class="btn btn__primary">
            ga naar de producten
          </A>
        </div>
      )}

      {cartStore.cartItems.length && <OrderList />}
    </div>
  );
}

export default Cart;
