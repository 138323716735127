import "./Callout.scss";
import { Dynamic } from "solid-js/web";

import IconAlertSquareRoundedFilled from "@tabler/icons-solidjs/dist/esm/icons/IconAlertSquareRoundedFilled.mjs";
import IconInfoSquareRoundedFilled from "@tabler/icons-solidjs/dist/esm/icons/IconInfoSquareRoundedFilled.mjs";
import IconSquareRoundedCheckFilled from "@tabler/icons-solidjs/dist/esm/icons/IconSquareRoundedCheckFilled.mjs";
import { JSXElement } from "solid-js";

function Callout({
  type = "info",
  title,
  children,
}: {
  type?: "info" | "warning" | "success";
  title?: string;
  children?: JSXElement;
}) {
  const icons = {
    info: IconInfoSquareRoundedFilled,
    warning: IconAlertSquareRoundedFilled,
    success: IconSquareRoundedCheckFilled,
  };
  return (
    <div class={`callout callout__${type}`}>
      <div class="callout_icon">
        <Dynamic component={icons[type]} />
      </div>
      <div class="callout_content">
        {title && <div class="callout_title">{title}</div>}
        <div class="callout_message">{children}</div>
      </div>
    </div>
  );
}

export default Callout;
