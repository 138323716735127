import IconZoomQuestion from "@tabler/icons-solidjs/dist/esm/icons/IconZoomQuestion.mjs";
// import { IconZoomQuestion } from "@tabler/icons-solidjs";
function NotFound() {
  return (
    <div class="centeredPageBlock">
      <IconZoomQuestion size="50" />
      <h1>Deze pagina is niet gevonden</h1>
    </div>
  );
}

export default NotFound;
