// import "normalize.css";
import "./styles/main.scss";

import { render } from "solid-js/web";
import { Router, Route } from "@solidjs/router";

import App from "./App";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Product from "./pages/Product";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://bb9c119bcf76e84bde97cf15bba248ff@o4507905520893952.ingest.de.sentry.io/4507905540948048",
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(), //increases bundle size a lot
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = document.getElementById("root") as HTMLElement;

render(
  () => (
    //@ts-ignore
    <Router root={App}>
      <Route path="/" component={Home} />
      <Route path="/cart" component={Cart} />
      <Route path="/checkout" component={Checkout} />
      <Route path="/producten/:category/:name_slug" component={Product} />
      <Route path="*paramName" component={NotFound} />
    </Router>
  ),
  root
);
