import { InfoBanner } from "../components/InfoBanner";
import PlantBrowser from "../components/ProductBrowser";
import { ReturnToTop } from "../components/ReturnToTop";

function Home() {
  return (
    <>
      <InfoBanner />
      <PlantBrowser />
      <ReturnToTop />
    </>
  );
}

export default Home;
